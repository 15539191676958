<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Products List</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Products List</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="shop-area ptb-100">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-6">
                    <div class="woocommerce-result-count">
                        <p>Showing 1-8 of 14 results</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-6">
                    <div class="woocommerce-topbar-ordering">
                        <select>
                            <option value="1">Default sorting</option>
                            <option value="2">Sort by popularity</option>
                            <option value="0">Sort by average rating</option>
                            <option value="3">Sort by latest</option>
                            <option value="4">Sort by price: low to high</option>
                            <option value="5">Sort by price: high to low</option>
                            <option value="6">Sort by new</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img src="assets/img/shop/products1.jpg" alt="image"></a>

                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class="flaticon-shopping-cart"></i></a>
                    </div>

                    <div class="product-content">
                        <h3><a routerLink="/products-details">Novel Bunch</a></h3>

                        <div class="price">
                            <span class="new">$455.50</span>
                            <span class="old">$460.50</span>
                        </div>

                        <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img src="assets/img/shop/products2.jpg" alt="image"></a>

                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class="flaticon-shopping-cart"></i></a>
                    </div>

                    <div class="product-content">
                        <h3><a routerLink="/products-details">Book Chicks</a></h3>

                        <div class="price">
                            <span class="new">$541.50</span>
                            <span class="old">$652.50</span>
                        </div>

                        <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star-half-alt"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img src="assets/img/shop/products3.jpg" alt="image"></a>

                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class="flaticon-shopping-cart"></i></a>
                    </div>

                    <div class="product-content">
                        <h3><a routerLink="/products-details">Book Divas</a></h3>

                        <div class="price">
                            <span class="new">$140.50</span>
                            <span class="old">$150.50</span>
                        </div>

                        <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="far fa-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img src="assets/img/shop/products4.jpg" alt="image"></a>

                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class="flaticon-shopping-cart"></i></a>
                    </div>

                    <div class="product-content">
                        <h3><a routerLink="/products-details">Book Smart</a></h3>

                        <div class="price">
                            <span class="new">$547.00</span>
                            <span class="old">$600.00</span>
                        </div>

                        <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img src="assets/img/shop/products5.jpg" alt="image"></a>

                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class="flaticon-shopping-cart"></i></a>
                    </div>

                    <div class="product-content">
                        <h3><a routerLink="/products-details">Book Broads</a></h3>

                        <div class="price">
                            <span class="new">$655.50</span>
                        </div>

                        <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="far fa-star"></i>
                            <i class="far fa-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img src="assets/img/shop/products6.jpg" alt="image"></a>

                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class="flaticon-shopping-cart"></i></a>
                    </div>

                    <div class="product-content">
                        <h3><a routerLink="/products-details">Page Turners</a></h3>

                        <div class="price">
                            <span class="new">$415.00</span>
                            <span class="old">$420.50</span>
                        </div>
                        
                        <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>