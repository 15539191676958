<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Cart</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Cart</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="product-thumbnail"><a routerLink="/"><img src="assets/img/shop/products1.jpg" alt="item"></a></td>

                            <td class="product-name"><a routerLink="/">Novel Bunch</a></td>

                            <td class="product-price"><span class="unit-amount">$455.00</span></td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn">
                                        <i class="fas fa-minus"></i>
                                    </span>
                                    
                                    <input type="text" value="1">

                                    <span class="plus-btn">
                                        <i class="fas fa-plus"></i>
                                    </span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$455.00</span>
                                <a routerLink="/" class="remove"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail"><a routerLink="/"><img src="assets/img/shop/products2.jpg" alt="item"></a></td>

                            <td class="product-name"><a routerLink="/">Book Chicks</a></td>

                            <td class="product-price"><span class="unit-amount">$541.50</span></td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn">
                                        <i class="fas fa-minus"></i>
                                    </span>

                                    <input type="text" value="1">

                                    <span class="plus-btn">
                                        <i class="fas fa-plus"></i>
                                    </span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$541.50</span>
                                <a routerLink="/" class="remove"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail"><a routerLink="/"><img src="assets/img/shop/products3.jpg" alt="item"></a></td>

                            <td class="product-name"><a routerLink="/">Book Divas</a></td>

                            <td class="product-price"><span class="unit-amount">$140.50</span></td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn">
                                        <i class="fas fa-minus"></i>
                                    </span>

                                    <input type="text" value="1">

                                    <span class="plus-btn">
                                        <i class="fas fa-plus"></i>
                                    </span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$140.50</span>
                                <a routerLink="/" class="remove"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail"><a routerLink="/"><img src="assets/img/shop/products4.jpg" alt="item"></a></td>

                            <td class="product-name"><a routerLink="/">Book Smart</a></td>

                            <td class="product-price"><span class="unit-amount">$547.00</span></td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn">
                                        <i class="fas fa-minus"></i>
                                    </span>

                                    <input type="text" value="1">
                                    
                                    <span class="plus-btn">
                                        <i class="fas fa-plus"></i>
                                    </span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$547.00</span>
                                <a routerLink="/" class="remove"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7">
                        <div class="shopping-coupon-code">
                            <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                            <button type="submit">Apply Coupon</button>
                        </div>
                    </div>

                    <div class="col-lg-5 col-sm-5 col-md-5 text-end">
                        <a routerLink="/" class="default-btn">Update cart-totals</a>
                    </div>
                </div>
            </div>

            <div class="cart-totals">
                <h3>Cart Totals</h3>

                <ul>
                    <li>Subtotal <span>$1683.50</span></li>
                    <li>Shipping <span>$30.00</span></li>
                    <li>Total <span><b>$1713.50</b></span></li>
                </ul>

                <a routerLink="/checkout" class="default-btn">Proceed to Checkout</a>
            </div>
        </form>
    </div>
</section>